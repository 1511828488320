export const navItems = [
  {
    key: 'autopilot',
    desktop: 'HR Autopilot',
    tablet: 'HR Autopilot',
  },
  {
    key: 'manager',
    desktop: 'HR Manager',
    tablet: 'HR Manager',
  },
  {
    key: 'payroll-addon',
    desktop: 'Bambee Guided Payroll',
    tablet: 'Payroll',
  },
  {
    key: 'about',
    desktop: 'Who We Are',
    tablet: 'About',
  },
  {
    key: 'resources',
    desktop: 'Resources',
    tablet: 'Resources',
  },
];

export const HeroContent = {
  title: `Put your HR on Autopilot`,
  subtitle: `plus a Dedicated HR Manager for $9/mo`,
  benefits: [
    { text: `Your Own Dedicated HR Manager` },
    { text: `HR Compliant Policies` },
    { text: `Employee Onboarding & Terminations` },
    { text: `Relief From Complex HR Issues` },
    { text: `Annual & Comprehensive HR Audits` },
    { text: `$9/mo for companies up to 500 employees` },
    { text: `Work Off Your Plate & Peace of Mind!` },
  ],
};

export const VideoHeader = {
  title: `How Does Bambee Work?`,
  subtitle: `With the help of your dedicated HR Manager, Bambee puts your HR on
    autopilot to streamline your HR, and automate your onboarding, policies,
    and even employee training.`,
};

export const Factoids = [
  {
    factoid: `Bambee clients are 4x less likely to have a complaint filed against them.`,
  },
  {
    quote: `As a small business, Bambee was exactly what we needed for HR. Affordable, professional, and friendly! We absolutely recommend them.`,
    image: `/assets/images/market/beautiful-people/BryanKelly@2x.png`,
    fullname: `Bryan Kelly`,
    title: `Owner & Vice President @ HOODZ Cleaning`,
  },
  {
    quote: `We are a growing company that needs help with our HR. We have hired Bambee and thus far have been very happy. Our HR Rep has been attentive and quick to respond with valuable HR knowledge. We are building our HR policies and processes way quicker than I alone would have been able to do.`,
    image: `/assets/images/market/beautiful-people/male-4.svg`,
    fullname: `Damon P`,
    title: `CFO in Leading Telecommunications Company`,
  },
  {
    factoid: `Only 12 percent of U.S. employees strongly agree that their company does a good job of onboarding new employees.`,
  },
];

export const Automations = [
  {
    id: 'autopilot-audit',
    image: '/assets/images/market/autopilot-identify.svg',
    header: '01 HR AUDITS',
    title: 'Quickly Identify Your HR Gaps',
    body: `HR rules change. Audits let you identify any HR gaps, and then we help you with an action plan to get your internal practices up-to-date to keep protecting your company and employees.`,
  },
  {
    id: 'autopilot-policy',
    image: '/assets/images/market/preview-policy.webp',
    header: '02 HR POLICIES',
    title: 'Policies That Protect Your Business and Standardize Your Culture',
    body: `HR Autopilot makes sure all core, protective policies are current, signed by your employees, and reaffirmed semi-annually.  Then, your dedicated HR Manager crafts any custom HR policies your business needs.`,
  },
  {
    id: 'autopilot-training',
    image: '/assets/images/market/autopilot-training.webp',
    header: '03 TRAINING & CERTIFICATIONS',
    title: 'Training That Keeps Your Staff Compliant and Productive',
    body: `Bambee takes care of important and often-mandatory training like sexual harassment, workplace safety, and business ethics - and reports back to you on everyone’s progress.`,
  },
  {
    id: 'autopilot-reportcard',
    image: '/assets/images/market/autopilot-reportcard.webp',
    header: '04 STAFF PERFORMANCE TRACKING',
    title: 'Deliver Feedback to Improve Employee Performance',
    body: `Bambee’s Report Cards help you track your staff against their goals, and open up a regular dialogue to give praise, constructive feedback, or take corrective action.`,
  },
  {
    id: 'autopilot-voices',
    image: '/assets/images/market/autopilot-voices.webp',
    header: '05 UNDERSTAND EMPLOYEE MORALE',
    title: 'Create A Space For Employees to Speak Their Thoughts',
    body: `Employee Voices lets your employees share concerns and alert you to potential problems.  But it doesn’t have to be bad news - it’s also a great way for your staff to express gratitude, anonymously or publicly.`,
  },
  {
    id: 'autopilot-cabinet',
    image: '/assets/images/market/autopilot-signature.webp',
    header: '06 COMPLIANT DOCUMENT STORAGE',
    title: 'Comply with EEOC Document Retention Standards',
    body: `Federal & state laws mandate that certain company files be held for a certain period of time.  Plus, never guess who signed what - or where your important HR documents are.  They’re all gathered safely in your Smart Cabinet.`,
  },
];

export const DedicatedHrList = [
  {
    icon: 'doorOpenSolid',
    title: 'Employee Relations & HR Problem Solving',
    body: 'The most sensitive issues require rich dialogue.  Your HR Manager engages with you to create the right outcomes for your business.',
  },
  {
    icon: 'clipboardCheckSolid',
    title: 'Craft HR Policy, Procedures & Shape Company Culture',
    body: 'Your HR Manager builds policies specific for your businesses based on labor regulation and your unique company culture for better alignment.',
  },
  {
    icon: 'badgeDollarSolid',
    title: 'Onboarding, Terminations & Navigating Labor Regulations',
    body: `Lean on Bambee for onboarding, terminations, and corrective actions and get clarity on all your regulatory questions.`,
  },
  {
    icon: 'binoculars',
    title: 'Employer Coaching & Guidance',
    body: 'Your HR Manager gets to know you on a first name basis and gives you full remote HR support plus coaching where you need it.',
  },
];

export const EmployeeAutopilot = {
  leftItems: [
    {
      icon: 'clipboardCheck',
      title: 'Automating Compliance Reviews Keeps Companies on Track',
      body: 'People want to work at companies that are making best efforts to follow fair HR practices and create rules-based environments.',
    },
    {
      icon: 'clipboardCheck',
      title: 'Understanding HR Policy Creates Alignment',
      body: 'Employees do better when they understand the rules around the culture they contribute to.',
    },
    {
      icon: 'postitCheck',
      title: 'Coaching Helps Employees Reach Professional Goals',
      body: 'Every employee deserves to know how they’re performing at their jobs and how they can perform better in their role',
    },
  ],
  rightItems: [
    {
      icon: 'presentation',
      title: 'In App Training Empowers Employees With Workplace Knowledge',
      body: 'Giving employees the tools to follow the rules increases the likelihood they do so.',
    },
    {
      icon: 'megaphone',
      title: 'Responding to Feedback Holds the Company Accountable',
      body: 'Resentment is the number one contributing factor to poor performance and labor claims. Giving employees a safe place to voice concerns is key to addressing them.',
    },
  ],
};

export const HowLowPrices = {
  items: [
    {
      icon: 'clipboardCheckSolid',
      title: 'Small Business Focused',
      body: 'We only service businesses with less than 500 employees. That means we deal with your types of needs all day, every day.',
    },
    {
      icon: 'doorOpenSolid',
      title: 'The Best of Modern Tech',
      body: 'We use cutting edge data technology to stay informed about labor laws & help you avoid mistakes.',
    },
  ],
  rightItems: [
    {
      icon: 'binoculars',
      title: 'HR Practice Automation',
      body: 'HR Autopilot automates your key HR practices to keep you HR compliant all year through.',
    },
  ],
};

export const NavPanels = {
  autopilotsBusiness: [
    {
      label: `FOR YOUR BUSINESS`,
      items: [
        {
          id: 'top-nav-hr-autopilot-hr-audits',
          title: 'HR Audits',
          body: 'Learn where your HR gaps are',
          scrollId: 'autopilot-audit',
        },
        {
          id: 'top-nav-hr-autopilot-training-certifications',
          title: 'Training & Certifications',
          body: 'Keeping your staff compliant',
          scrollId: 'autopilot-training',
        },
        {
          id: 'top-nav-hr-autopilot-hr-policies',
          title: 'HR Policies',
          body: 'Core protective documents and customized policies',
          scrollId: 'autopilot-policy',
        },
        {
          id: 'top-nav-hr-autopilot-cabinet',
          title: 'Cabinet',
          body: 'Storing and securing important HR documents',
          scrollId: 'autopilot-cabinet',
        },
      ],
    },
    {
      label: `FOR YOUR EMPLOYEES`,
      items: [
        {
          id: 'top-nav-hr-autopilot-employee-feedback',
          title: 'Employee Feedback',
          body: `So your employees know how they're doing`,
          scrollId: 'autopilot-reportcard',
        },
        {
          id: 'top-nav-hr-autopilot-employee-voices',
          title: 'Employee Voices',
          body: 'Giving your team an outlet to speak up',
          scrollId: 'autopilot-voices',
        },
      ],
    },
  ],
  hrManager: [
    {
      label: `FOR YOUR BUSINESS`,
      items: [
        {
          id: 'top-nav-hr-manager-ongoing-relationship',
          title: 'Ongoing Relationship',
          body: 'There for you through the whole year',
          scrollId: 'dedicated-manager',
        },
        {
          id: 'top-nav-hr-manager-custom-hr-policies',
          title: 'Custom HR Policies',
          body: 'Policies to fit your specific needs',
          scrollId: 'dedicated-manager',
        },
        {
          id: 'top-nav-hr-manager-onboarding',
          title: 'Employee Management',
          body: 'Bringing on and dismiss employees the compliant way',
          scrollId: 'dedicated-manager',
        },
        {
          id: 'top-nav-hr-manager-navigate-labor-regulations',
          title: 'Navigate Labor Regulations',
          body: 'Helping to keep your company safe',
          scrollId: 'dedicated-manager',
        },
        {
          id: 'top-nav-hr-manager-phone-email-chat-support',
          title: 'Phone, Email, and Chat Support',
          body: 'Communication on your terms',
          scrollId: 'hrm-available',
        },
      ],
    },
    {
      label: `FOR YOUR EMPLOYEES`,
      items: [
        {
          id: 'top-nav-hr-manager-employee-relations',
          title: 'Employee Relations',
          body: 'Heard employees are happy employees',
          scrollId: 'dedicated-manager',
        },
        {
          id: 'top-nav-hr-manager-field-employee-concerns',
          title: 'Field Employee Concerns',
          body: 'Taking care of concerns before they become complaints',
          scrollId: 'dedicated-manager',
        },
      ],
    },
  ],
  payroll: [
    {
      label: `KEY FEATURES`,
      items: [
        {
          id: 'top-nav-bambee-payroll-2-day-payroll',
          title: '2-Day Payroll',
          body: 'Get your employees paid quickly with direct deposit',
          scrollId: 'payroll-addon',
        },
        {
          id: 'top-nav-bambee-payroll-automatic-taxes',
          title: 'Automatic Taxes',
          body: 'Bambee takes care of federal, state, and local',
          scrollId: 'payroll-addon',
        },
        {
          id: 'top-nav-bambee-payroll-employee-misclassification-protection',
          title: 'Employee Misclassification Protection',
          body: 'Part time? Full time? Contractor?  We can help.',
          scrollId: 'payroll-addon',
        },
      ],
    },
    {
      label: `HR MANAGER SUPPORT`,
      items: [
        {
          id: 'top-nav-bambee-payroll-wage-compliance-guidance',
          title: 'Wage & Compliance Guidance',
          body: 'Avoid costly miscalculations',
          scrollId: 'payroll-addon',
        },
      ],
    },
  ],
  about: [
    {
      label: `WHO WE ARE`,
      items: [
        {
          id: 'top-nav-bambee-about',
          title: 'About',
          body: 'Our mission is big & our people are bold',
          link: '/about',
        },
        {
          id: 'top-nav-bambee-about-leadership',
          title: 'Leadership',
          body: 'Our leadership team is ambitious, direct, and proven',
          link: '/about/leadership',
        },
        {
          id: 'top-nav-bambee-about-teams',
          title: 'Teams',
          body: 'Teams that believe in dialog, in-person work, and results',
          link: '/about/teams',
        },
        {
          id: 'top-nav-bambee-about-culture',
          title: 'Culture',
          body: 'We’re straight forward and proud of who we are',
          link: '/about/culture',
        },
        {
          id: 'top-nav-bambee-about-investors',
          title: 'Investors',
          body: 'We’re building a game changing American brand',
          link: '/about/investors',
        },
        {
          id: 'top-nav-bambee-about-hr-managers',
          title: 'HR Managers',
          body: 'We deliver HR with empathy to small business',
          link: '/about/hr-managers',
        },
        {
          id: 'top-nav-bambee-careers',
          title: 'Careers',
          body: 'Join Bambee',
          link: 'about/careers',
        },
      ],
    },
  ],
  resources: [
    {
      label: `RESOURCES`,
      items: [
        {
          id: 'top-nav-bambee-blog',
          title: 'Blog',
          body: 'See the latest in industry trends and topics',
          link: '/blog',
        },
      ],
    },
  ],
};

export default {
  DedicatedHrList,
  EmployeeAutopilot,
  Factoids,
  HeroContent,
  navItems,
  NavPanels,
  VideoHeader,
};
